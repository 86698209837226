/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { lazyImport } from "@/utils/lazyImport";

const { RidesPage } = lazyImport(() => import("../pages/RidesPage"), "RidesPage");

export function RidesRoutes() {
    return (
        <Routes>
            <Route path="/" element={<RidesPage />} />
            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    );
}
