import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { User } from "@/features/auth";
import { storage } from "@/functions";
import { toast } from "@/components";
import { API_URL } from "./constants";

export const apiClient = axios.create({
    baseURL: API_URL,
});

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const user = storage.getWithExpiry<User>("user");

    const newConfig = { ...config };

    if (user && config.method === "get") {
        newConfig.params = { token: user.token, ...newConfig.params };
    }

    if (user && config.method === "post") {
        newConfig.data = {
            token: user.token,
            ...newConfig.data,
        };
    }

    return newConfig;
});

apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.data === "pas d'action" || response.data === "action inconnu") {
            toast.error("Un problème est survenu");
            return Promise.reject(response);
        }
        if (response.data?.error === true) {
            const message = response.data?.message;

            if (message !== "Ce token est déja enregistré") {
                toast.error(message || "Un problème est survenu");
            }

            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        const message = error.response?.data?.message || error.message;
        toast.error(message || "Un problème est survenu");
        return Promise.reject(error);
    },
);
