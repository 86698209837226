import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/en";
import "dayjs/locale/fr";

type DateType = string | number | Date | Dayjs;

export const initDateManager = (
    defaultLocale: string = "fr",
    defaultTimeZone: string = "Europe/Paris",
) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(localizedFormat);

    dayjs.locale(defaultLocale);
    dayjs.tz.setDefault(defaultTimeZone);
};

export const createDate = (date?: DateType) => {
    return dayjs.tz(date).toDate();
};

export const formatDate = (date: DateType, format: string): string => {
    return dayjs.tz(date).format(format);
};

export const formatTime = (time: string, format: string = "HH[h]mm"): string => {
    const timeFormatPattern = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

    if (!timeFormatPattern.test(time)) {
        throw new Error('Time must be in the format "HH:MM:SS"');
    }

    return dayjs.tz(`1970-01-01T${time}`).format(format);
};

export const toDate = (date: DateType): Date => {
    return dayjs.tz(date).toDate();
};

export const addToDate = (
    date: DateType,
    units: {
        years?: number;
        months?: number;
        weeks?: number;
        days?: number;
        hours?: number;
        minutes?: number;
        seconds?: number;
    },
): Date => {
    let newDate = dayjs.tz(date);
    if (units.years) newDate = newDate.add(units.years, "year");
    if (units.months) newDate = newDate.add(units.months, "month");
    if (units.weeks) newDate = newDate.add(units.weeks, "week");
    if (units.days) newDate = newDate.add(units.days, "day");
    if (units.hours) newDate = newDate.add(units.hours, "hour");
    if (units.minutes) newDate = newDate.add(units.minutes, "minute");
    if (units.seconds) newDate = newDate.add(units.seconds, "second");

    return newDate.toDate();
};

export const isSameDate = (date1: DateType, date2: DateType): boolean => {
    return dayjs.tz(date1).isSame(dayjs.tz(date2), "day");
};

export const isBeforeDate = (beforeDate: DateType, afterDate: DateType): boolean => {
    return dayjs.tz(beforeDate).isBefore(dayjs.tz(afterDate));
};

export const startOfDate = (date: Date): Date => {
    return dayjs.tz(date).startOf("day").toDate();
};
