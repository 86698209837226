import { useContext } from "react";

import { PermissionContextProps, PermissionsContext } from "./PermissionsContext";

export function usePermissions<User, Role>(): PermissionContextProps<User, Role> {
    const context = useContext(PermissionsContext);

    if (context === undefined) {
        throw new Error("usePermissions est utilisé en dehors de PermissionsProvider");
    }

    return context;
}
