import { apiClient } from "@/config/axios";

import { User } from "../types";

export type LoginCredentialsDTO = {
    username: string;
    password: string;
};

export const login = (request: LoginCredentialsDTO): Promise<User> => {
    const { username, password } = request;

    return apiClient
        .get(`login.php`, {
            params: {
                username,
                password,
            },
        })
        .then((response) => response.data.data);
};
