export enum Role {
    DRIVER = "chauffeur",
    GUEST = "guest",
    USER = "client",
}

export type User = {
    token: string;
    name: string;
    first_name: string;
    email: string;
    phone: string;
    user_type: Role;
    qr_code_url: string;
};
