import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./App";

import { SENTRY_DSN } from "./config/constants";

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
