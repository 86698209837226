import { ReactNode } from "react";

import { Stack as MuiStack, StackProps as MuiStackProps } from "@mui/material";

export interface StackProps
    extends Pick<
        MuiStackProps,
        "className" | "direction" | "gap" | "justifyContent" | "alignItems"
    > {
    children: ReactNode;
}

export function Stack({ children, ...rest }: StackProps) {
    return (
        <MuiStack useFlexGap {...rest}>
            {children}
        </MuiStack>
    );
}
