import { useEffect } from "react";
import { useWakeLock } from "react-screen-wake-lock";

const useScreenLock = () => {
    const { isSupported, request, release } = useWakeLock();

    useEffect(() => {
        const onWindowFocus = () => request();
        const onWindowBlur = () => release();

        if (isSupported) {
            window.addEventListener("focus", onWindowFocus);
            window.addEventListener("blur", onWindowBlur);

            onWindowFocus();
        }

        return () => {
            window.removeEventListener("focus", onWindowFocus);
            window.removeEventListener("blur", onWindowBlur);
        };
    }, [isSupported, release, request]);
};

export default useScreenLock;
