import {
    toast as reactToastify,
    ToastContentProps as ReactToastifyToastContentProps,
} from "react-toastify";
import { Typography } from "../Typography/Typography";
import { CheckIcon } from "../Icons/CheckIcon";
import { ErrorIcon } from "../Icons/ErrorIcon";

export type ToastContentProps = Partial<ReactToastifyToastContentProps>;

type Toast = {
    success: (message: string) => React.ReactNode;
    error: (message: string) => React.ReactNode;
};

export const toast: Toast = {
    success: (message: string) =>
        reactToastify.success(() => <Typography>{message}</Typography>, {
            icon: <CheckIcon />,
        }),

    error: (message: string) =>
        reactToastify.error(() => <Typography>{message}</Typography>, {
            icon: <ErrorIcon />,
        }),
};
