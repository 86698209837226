import { Backdrop as MuiBackdrop } from "@mui/material";

import Styles from "./FullPageSpinner.module.scss";
import { Spinner } from "../Spinner/Spinner";

export function FullPageSpinner() {
    return (
        <MuiBackdrop className={Styles.Backdrop} open>
            <Spinner color="inherit" size={100} thickness={1.5} />
        </MuiBackdrop>
    );
}
