import React, { PropsWithChildren } from "react";

// import { ThemeProvider, ToastRenderer } from "@resamare/ui";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./ThemeProvider";
import { ErrorBoundary, FullPageSpinner, ToastRenderer } from "@/components";
import { queryClient } from "@/config/react-query";
import { PermissionsProvider } from "@/config/permissions";
import { AuthLoader } from "@/config/auth";
import { initDateManager } from "@/functions";
import useScreenLock from "@/hooks/useScreenLock";

export function AppProvider({ children }: PropsWithChildren) {
    useScreenLock();
    initDateManager();

    return (
        <React.Suspense fallback={<FullPageSpinner />}>
            <ThemeProvider>
                <ErrorBoundary>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools position="bottom-left" />
                        <PermissionsProvider>
                            <BrowserRouter>
                                <AuthLoader renderLoading={() => <FullPageSpinner />}>
                                    {children}
                                </AuthLoader>
                            </BrowserRouter>
                        </PermissionsProvider>
                    </QueryClientProvider>
                </ErrorBoundary>
                <ToastRenderer />
            </ThemeProvider>
        </React.Suspense>
    );
}
