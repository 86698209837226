import { PropsWithChildren, ReactElement, useEffect } from "react";

import {
    CssBaseline,
    CssVarsThemeOptions,
    Experimental_CssVarsProvider as CssVarsProvider,
    StyledEngineProvider,
    experimental_extendTheme as createTheme,
} from "@mui/material";

import { theme } from "@/config/theme/theme";

export type ThemeOptions = CssVarsThemeOptions;

export function ThemeProvider({ children }: PropsWithChildren): ReactElement {
    const customTheme = createTheme(theme);

    useEffect(() => {
        document.documentElement.style.setProperty(`--navette-spacing`, customTheme.spacing(1));
    }, [customTheme]);

    return (
        <CssVarsProvider theme={customTheme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline>{children}</CssBaseline>
            </StyledEngineProvider>
        </CssVarsProvider>
    );
}
