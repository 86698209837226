import { Navigate, Outlet, useLocation } from "react-router-dom";

import { usePermissions } from "./usePermissions";

export type ProtectedRouteOptions = {
    loginUrl: string;
    unauthorizedUrl: string;
};

const defaultOptions: ProtectedRouteOptions = {
    loginUrl: "/auth/login",
    unauthorizedUrl: "/auth/unauthorized",
};

export type ProtectedRouteProps<Role> = {
    allowedRoles: Role[];
    options?: Partial<ProtectedRouteOptions>;
};

export function ProtectedRoute<User, Role>({
    allowedRoles,
    options = {},
}: ProtectedRouteProps<Role>) {
    const { user, roles } = usePermissions<User, Role>();
    const location = useLocation();

    const mergedOptions: ProtectedRouteOptions = { ...defaultOptions, ...options };

    if (!user) {
        return <Navigate replace state={{ from: location }} to={mergedOptions.loginUrl} />;
    }

    if (allowedRoles.length && !allowedRoles.some((role) => roles.includes(role))) {
        return <Navigate replace state={{ from: location }} to={mergedOptions.unauthorizedUrl} />;
    }

    return <Outlet />;
}
