import { FallbackProps as ReactErrorFallbackProps } from "react-error-boundary";

import Styles from "./ErrorFallback.module.scss";
import { Stack } from "../../Stack/Stack";
import { ErrorIcon } from "../../Icons/ErrorIcon";
import { Typography } from "../../Typography/Typography";
import { Button } from "../../Button/Button";

export function ErrorFallback({ error, resetErrorBoundary }: ReactErrorFallbackProps) {
    return (
        <Stack className={Styles.errorFallback} gap={1}>
            <div className={Styles.icon}>
                <ErrorIcon />
            </div>
            <Typography align="center" variant="title-sm">
                Oops, il semble y avoir un problème !
            </Typography>
            <Typography align="center">{error.message}</Typography>
            <Button onClick={resetErrorBoundary}>Réessayer</Button>
        </Stack>
    );
}
