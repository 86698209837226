/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "@/config/permissions";
import { Role } from "../types";
import { useUser } from "@/config/auth";
import { lazyImport } from "@/utils/lazyImport";

const { ForbiddenPage } = lazyImport(() => import("../pages"), "ForbiddenPage");
const { LoginPage } = lazyImport(() => import("../pages"), "LoginPage");
const { LogoutPage } = lazyImport(() => import("../pages"), "LogoutPage");

export function AuthRoutes() {
    const { data: user } = useUser();

    return (
        <Routes>
            {!user ? <Route path="/login" element={<LoginPage />} /> : null}

            <Route element={<ProtectedRoute allowedRoles={[Role.DRIVER]} />}>
                <Route path="/logout" element={<LogoutPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Role.DRIVER, Role.USER]} />}>
                <Route path="/unauthorized" element={<ForbiddenPage />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
