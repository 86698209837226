import { configureAuth } from "react-query-auth";

import { login, LoginCredentialsDTO, User } from "@/features/auth";
import { toast } from "@/components";
import { storage } from "@/functions";

const USER_KEY = "user";
const ONE_MONTH = 30 * 86400000;

async function handleUserResponse(user?: User) {
    if (!user) {
        toast.error("Il y a eu un problème lors de la connexion");
        return null;
    }
    storage.setWithExpiry(USER_KEY, user, ONE_MONTH);
    return user;
}

async function loginFn(data: LoginCredentialsDTO) {
    const response = await login(data);
    const user = await handleUserResponse(response);
    return user;
}

async function logoutFn() {
    storage.remove(USER_KEY);
}

async function userFn() {
    const user = storage.getWithExpiry<User>(USER_KEY);
    if (!user) return null;
    return user;
}

export const { useUser, useLogin, useRegister, useLogout, AuthLoader } = configureAuth({
    loginFn,
    logoutFn,
    userFn,

    registerFn: () => Promise.resolve(null),
});
