import "./Typography.d";

import { ElementType } from "react";

import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@mui/material";

import Styles from "./Typography.module.scss";

// type TypographyProps = Pick<MuiTypographyProps, "className" | "variant">;

export interface Props
    extends Pick<MuiTypographyProps, "className" | "children" | "variant" | "align"> {
    fontWeight?: 400 | 600 | 700;
    /**
     * Applies the theme typography styles.
     * @default 'text'
     */
    variant?:
        | "text"
        | "text-sm"
        | "text-xs"
        | "title"
        | "title-sm"
        | "title-xs"
        | "label"
        | "helper-text"
        | "menu-label";
    component?: ElementType;
}

export function Typography({
    className,
    children,
    variant = "text",
    component = "p",
    fontWeight,
    ...rest
}: Props) {
    return (
        <MuiTypography
            className={`${Styles[variant]} ${
                fontWeight ? Styles[`font-weight-${fontWeight}`] : ""
            } ${className || ""}`}
            component={component}
            {...rest}
        >
            {children}
        </MuiTypography>
    );
}
