import { PropsWithChildren } from "react";

import { Role, User } from "@/features/auth";

import { useUser } from "./auth";
import { initPermissions } from "@/components";

const {
    PermissionsProvider: BasePermissionsProvider,
    usePermissions,
    ProtectedRoute,
} = initPermissions<User | null | undefined, Role>();

function PermissionsProvider({ children }: PropsWithChildren) {
    const user = useUser();

    const userRole = user.data?.user_type;

    const roles = userRole ? [userRole] : [Role.GUEST];

    return (
        <BasePermissionsProvider roles={roles} user={user.data}>
            {children}
        </BasePermissionsProvider>
    );
}

export { PermissionsProvider, usePermissions, ProtectedRoute };
