import { CircularProgress as MuiCircularProgress, CircularProgressProps } from "@mui/material";
import { Stack } from "../Stack/Stack";

export type SpinnerProps = Pick<
    CircularProgressProps,
    "className" | "color" | "size" | "thickness"
>;

export function Spinner({
    className,
    color = "primary",
    size = 40,
    thickness = 3.6,
}: SpinnerProps) {
    return (
        <Stack alignItems="center">
            <MuiCircularProgress
                className={className}
                color={color}
                size={size}
                thickness={thickness}
            />
        </Stack>
    );
}
