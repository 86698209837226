/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";

import { AuthRoutes, Role } from "@/features/auth";
import { ProtectedRoute } from "@/config/permissions";
import { RidesRoutes } from "@/features/rides/routes/RidesRoutes";

export function AppRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute allowedRoles={[Role.DRIVER]} />}>
                <Route path="/" element={<Navigate to="/courses" />} />
                <Route path="/courses/*" element={<RidesRoutes />} />
            </Route>

            <Route element={<AuthRoutes />} path="/auth/*" />

            <Route element={<Navigate to="/" />} path="*" />
        </Routes>
    );
}
