export const CSS_VARIABLE_PREFIX = "navette";

export const BREAKPOINTS = {
    XS: 0,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
};

export const COLOR = {
    PRIMARY: "#2b6fb4",
    TEXT: "#333333",

    GREY_500: "#747474",
};

export const FONT_FAMILY = {
    MAIN: "Poppins, Arial, sans-serif",
};

export const SHAPE = {
    BORDER_RADIUS: 8,
};
