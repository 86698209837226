
            const NOTIFICATION_URL = import.meta.env.VITE_NOTIFICATION_URL;

            window.addEventListener("load", () => {
                if ("serviceWorker" in navigator) {
                    navigator.serviceWorker.register(
                        `/service-worker.js?notification-url=${encodeURIComponent(
                            NOTIFICATION_URL,
                        )}`,
                    );
                }
            });
        