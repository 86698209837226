import { FC } from "react";

import {
    PermissionContextProps,
    PermissionsProvider,
    PermissionsProviderProps,
} from "./PermissionsContext";
import { ProtectedRoute, ProtectedRouteProps } from "./ProtectedRoute";
import { usePermissions } from "./usePermissions";

type FactoryProps<User, Role> = {
    PermissionsProvider: FC<PermissionsProviderProps<User, Role>>;
    usePermissions: () => PermissionContextProps<User, Role>;
    ProtectedRoute: FC<ProtectedRouteProps<Role>>;
};

export function initPermissions<User, Role>(): FactoryProps<User, Role> {
    return {
        PermissionsProvider,
        usePermissions,
        ProtectedRoute,
    };
}
