import { PropsWithChildren } from "react";
import {
    ErrorBoundary as ReactErrorBoundary,
    ErrorBoundaryProps as ReactErrorBoundaryProps,
} from "react-error-boundary";

import { ErrorFallback } from "./ErrorFallback/ErrorFallback";

export type ErrorBoundaryProps = PropsWithChildren<
    Pick<ReactErrorBoundaryProps, "FallbackComponent">
>;

export function ErrorBoundary({ children, FallbackComponent = ErrorFallback }: ErrorBoundaryProps) {
    return (
        <ReactErrorBoundary FallbackComponent={FallbackComponent}>{children}</ReactErrorBoundary>
    );
}
